import {GiSeaTurtle, GiRunningNinja} from 'react-icons/gi';
import {IoIosPeople} from 'react-icons/io';
import {TbCards} from 'react-icons/tb';

export const tournamentTypes = [
    {
        icon: GiRunningNinja,
        name: 'Fast tournament',
        maxPlayers: 10,
        minPlayers: 3,
        //playTime: 90,
        levelUpTime: 15,
        startingStack: 2000,
        stackStructure: {
            500: 1,
            100: 12,
            25: 12,
        },
        blinds: [
            [25, 50],
            [50, 100],
            [75, 150],
            [100, 200],
            [200, 400],
            [400, 800],
            [800, 1600],
            [1600, 3200],
        ],
    },
    {
        icon: GiSeaTurtle,
        name: 'Slow tournament',
        maxPlayers: 10,
        minPlayers: 3,
        //playTime: 165,
        levelUpTime: 20,
        startingStack: 1500,
        stackStructure: {
            500: 1,
            100: 5,
            50: 5,
            20: 10,
            10: 5,
        },

        blinds: [
            [10, 20],
            [20, 40],
            [30, 60],
            [50, 100],
            [150, 300],
            [200, 400],
            [400, 800],
            [800, 1600],
        ],
    },
    {
        icon: IoIosPeople,
        name: 'Quick 3-table tournament',
        maxPlayers: 30,
        minPlayers: 10,
        //playTime: 180,
        levelUpTime: 20,
        startingStack: 2500,
        stackStructure: {
            500: 2,
            100: 12,
            20: 12,
        },

        blinds: [
            [25, 25],
            [20, 50],
            [50, 100],
            [100, 200],
            [200, 400],
            [400, 800],
            [1000, 2000],
            [2000, 4000],
            [4000, 8000],
        ],
    },
    {
        icon: TbCards,
        name: 'Standard T1000',
        maxPlayers: 10,
        minPlayers: 3,
        //playTime: 210,
        levelUpTime: 20,
        startingStack: 1000,
        stackStructure: {
            100: 5,
            25: 16,
            5: 20,
        },

        blinds: [
            [10, 20],
            [15, 30],
            [20, 40],
            [25, 50],
            [50, 100],
            [75, 150],
            [100, 200],
            [150, 300],
            [200, 400],
            [300, 600],
            [400, 800],
            [500, 1000],
            [600, 1200],
            [800, 1600],
            [1000, 2000],
        ],
    },
];
