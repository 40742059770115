import React from 'react';
import {Link} from 'react-router-dom';
import {PiPokerChip} from 'react-icons/pi';
import {CiBeerMugFull} from 'react-icons/ci';
import {GiPokerHand} from 'react-icons/gi';
import PropTypes from 'prop-types';

export const Button = ({icon, text, action, linkTo, disabled}) => {
    return (
        <>
            {action && (
                <button
                    className="w-full mb-5 mx-auto button__default"
                    onClick={() => {
                        action();
                    }}
                    disabled={disabled}
                >
                    {icon === 'chip' && <PiPokerChip size={40} />}
                    {icon === 'beer' && <CiBeerMugFull size={40} />}
                    {icon === 'start' && <GiPokerHand size={40} />}
                    <span className="px-3 w-full text-center">{text}</span>
                </button>
            )}

            {linkTo && (
                <Link className={`w-full mb-5 mx-auto button__default ${disabled ? 'disabled' : ''}`} to={linkTo}>
                    {icon === 'chip' && <PiPokerChip size={40} />}
                    {icon === 'beer' && <CiBeerMugFull size={40} />}
                    {icon === 'start' && <GiPokerHand size={40} />}
                    <span className="px-3 w-full text-center">{text}</span>
                </Link>
            )}
        </>
    );
};

Button.propTypes = {
    icon: PropTypes.string,
    text: PropTypes.string,
    action: PropTypes.func,
    linkTo: PropTypes.string,
    disabled: PropTypes.bool,
};
