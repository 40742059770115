import React, {useEffect} from 'react';
import {TournamentPicker} from './TournamentPicker.jsx';
import {Logo} from 'modules/Components/Logo.jsx';
import {useDataDispatch} from 'modules/DataState/DataState.jsx';
const config = require('modules/config');
const defaultData = structuredClone(require('modules/DataState/dataStateDefaultData.json'));
console.log('defaultData', defaultData);

export const Start = () => {
    // Context
    const dataDispatch = useDataDispatch();

    /**
     * Clear old tournament data and status
     */
    useEffect(() => {
        dataDispatch({type: 'clearTournamentData'});
        dataDispatch({type: 'clearTournamentStatus'});
    }, []);

    return (
        <main className="main justify-center">
            <Logo />
            <section className="flex flex-wrap items-start mx-auto py-5 max-w-full lg:max-w-5xl mt-0">
                {config.tournamentTypes.map((tournamentData, i) => {
                    return <TournamentPicker key={`t_${i}`} data={tournamentData} tournamenId={i} />;
                })}
                <span className="py-0 mb-5 flex justify-center w-full">--- More comming soon ... ---</span>
            </section>
        </main>
    );
};
