export const toHoursAndMinutes = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;

    if (hours && minutes) {
        return `${hours > 0 ? `${hours}h` : ''} ${minutes < 10 ? `0${minutes}min` : `${minutes}min`}`;
    } else if (!hours && minutes) {
        return `${minutes < 10 ? `0${minutes}min` : `${minutes}min`}`;
    } else if (hours && !minutes) {
        return `${hours > 0 ? `${hours}h` : ''}`;
    }
};

export const toMinutesAndSeconds = (totalSeconds) => {
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds - minutes * 60;

    return [
        `${minutes < 10 ? `0${minutes}` : minutes.toString()}`,
        `${seconds < 10 ? `0${seconds}` : seconds.toString()}`,
    ];
};
