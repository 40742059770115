// Imports
import React from 'react';
import {Routes, Route} from 'react-router-dom';
import {DataStateProvider} from './DataState/DataState.jsx';
import {Header} from './Header/Header.jsx';
import {Share} from './Share/Share.jsx';
import {Footer} from './Footer/Footer.jsx';
import {Homepage} from './Homepage/Homepage.jsx';
import {Start} from './Start/Start.jsx';
import {Donate} from './Donate/Donate.jsx';
import {Tournament} from './Tournament/Tournament.jsx';

let dataStateDefaultData = structuredClone(require('modules/DataState/dataStateDefaultData.json'));
if (localStorage.getItem('tournamentData')) {
    // Load tournament data from local storage if present
    dataStateDefaultData.tournamentData = JSON.parse(localStorage.getItem('tournamentData'));
    console.log('Tournament data in local storage - loading ...');
} else {
    console.log('No tournament data in local storage - not loading.');
}

if (localStorage.getItem('tournamentStatus')) {
    // Load tournament data from local storage if present
    dataStateDefaultData.tournamentStatus = JSON.parse(localStorage.getItem('tournamentStatus'));
    console.log('Tournament status in local storage - loading ...');
} else {
    console.log('No tournament status in local storage - not loading.');
}

export const App = () => {
    return (
        <div className="flex flex-col justify-between items-center  min-h-100vh text-white bg-cover">
            <DataStateProvider defaultData={dataStateDefaultData}>
                <Header />

                <Routes>
                    <Route path="/" element={<Homepage />} />
                    <Route path="/start" element={<Start />} />
                    <Route path="/donate" element={<Donate />} />
                    <Route path="/tournament" element={<Tournament />} />
                </Routes>

                <Share
                    pageUrl="https://72.r3k.pl"
                    facebook={true}
                    x={true}
                    xText="Manage your poker tournaments with ease using the 72 app. Try it now!"
                    telegram={true}
                    telegramText="Manage your poker tournaments with ease using the 72 app. Try it now!"
                    linkedin={false}
                    pinterest={false}
                    pinterestText="Manage your poker tournaments with ease using the 72 app. Try it now!"
                    pinterestMedia="https://72.r3k.pl/files/72app.jpg"
                />

                <Footer />
            </DataStateProvider>
        </div>
    );
};
