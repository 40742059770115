import React from 'react';
//import logo from 'images/72_640x640.png';
import {Logo} from 'modules/Components/Logo.jsx';
import {Button} from 'modules/Components/Button.jsx';

export const Homepage = () => {
    return (
        <main className="p-3 mx-auto flex flex-col flex-grow justify-center">
            <Logo noJumpout={true} />
            <Button icon="chip" text="Start tournament" linkTo="/start" />
            <span className="py-0 my-5 flex justify-center w-full">--- OR ---</span>
            <Button icon="beer" text="Buy me a beer" linkTo="/donate" />
            <span className="py-0 my-5 flex justify-center w-full">... there is no other option.</span>
        </main>
    );
};
