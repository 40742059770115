import React, {useState, useEffect} from 'react';
import {Logo} from 'modules/Components/Logo.jsx';
import {Timer} from './Timer.jsx';
import {useDataState, useDataDispatch} from 'modules/DataState/DataState.jsx';
import {getLevelBlinds} from './tournamentHelpers';
import {Payout} from './Payout.jsx';
const levelUpSound = new Audio('files/levelup.mp3');

export const Tournament = () => {
    // State
    const [blindLevels, setBlindLevels] = useState(null);
    const [maxLevel, setMaxLevel] = useState(null);

    // Context
    const dataDispatch = useDataDispatch();
    const {tournamentData, tournamentStatus} = useDataState();

    // Refresh data when required
    useEffect(() => {
        if (
            tournamentData.blinds &&
            tournamentStatus.currentLevel !== undefined &&
            tournamentStatus.totalStack !== undefined
        ) {
            const newBlindLevels = getLevelBlinds(
                tournamentData.blinds,
                tournamentStatus.currentLevel,
                tournamentStatus.totalStack,
            );
            setBlindLevels([newBlindLevels[0], newBlindLevels[1], newBlindLevels[2]]);
            setMaxLevel(newBlindLevels[3]);
        }
    }, [tournamentData, tournamentStatus]);

    // Set max level
    useEffect(() => {
        maxLevel && dataDispatch({type: 'setMaxLevel', payload: maxLevel});
    }, [maxLevel]);

    // Act on mount
    useEffect(() => {
        // Set total stack
        dataDispatch({
            type: 'setTotalStack',
            payload: tournamentStatus.tournamentPlayers[tournamentData.id] * tournamentData.startingStack,
        });

        // Cleanup
        return () => {
            dataDispatch({type: 'clearTournamentData'});
            dataDispatch({type: 'clearTournamentStatus'});
        };
    }, []);

    /**
     * Callback function called when timer reaches 0
     */
    const timerCallback = () => {
        levelUpSound.play();
        dataDispatch({type: 'setCurrentLevel', payload: tournamentStatus.currentLevel + 1});
    };

    return (
        <main className="main">
            <Logo noJumpout={false} fastJumpout={true} />
            {tournamentData.name ? (
                <section className="flex flex-grow flex-col justify-around">
                    <h2 className="text-center flex items-center justify-center">
                        {tournamentData.icon ? (
                            <tournamentData.icon className="w-16 h-16" />
                        ) : tournamentData.iconSVG ? (
                            <span
                                dangerouslySetInnerHTML={{__html: tournamentData.iconSVG}}
                                className="flex items-center w-16 h-16 icon-full"
                            ></span>
                        ) : (
                            ''
                        )}{' '}
                        <span className="pl-2 text-base md:text-lg xl:text-xl 2xl:text-3xl">{tournamentData.name}</span>
                    </h2>
                    <Timer timerCallback={timerCallback} />

                    {blindLevels && (
                        <ul className="list-none p-0 m-0 mx-auto">
                            {blindLevels[0] ? (
                                <li className="text-2xl opacity-25 py-2">
                                    {blindLevels[0][0]} / {blindLevels[0][1]}
                                </li>
                            ) : (
                                <li className="text-2xl opacity-25 py-2">---</li>
                            )}
                            <li className="text-4xl font-extrabold py-2 pl-10">
                                ({tournamentStatus.currentLevel + 1}) {blindLevels[1][0]} / {blindLevels[1][1]}
                            </li>
                            <li className="text-2xl opacity-50 py-2">
                                {blindLevels[2][0]} / {blindLevels[2][1]}
                            </li>
                        </ul>
                    )}
                    <Payout players={tournamentStatus.tournamentPlayers[tournamentData['id']]} />
                </section>
            ) : (
                <p>
                    No tournament selected ... <br />
                    Please go back and select one.
                </p>
            )}
        </main>
    );
};
