import React, {useState, useEffect} from 'react';
import {useDataState, useDataDispatch} from 'modules/DataState/DataState.jsx';
import {useNavigate} from 'react-router-dom';
import {Button} from 'modules/Components/Button.jsx';
import {toHoursAndMinutes} from 'helpers/timeHelpers';
import {getLevelBlinds} from '../Tournament/tournamentHelpers';
import {LuChevronDown, LuChevronUp} from 'react-icons/lu';
import {IoPersonCircleOutline} from 'react-icons/io5';
import {AiOutlineFieldTime} from 'react-icons/ai';
import {BiTimer} from 'react-icons/bi';
import {HiMiniCircleStack} from 'react-icons/hi2';
import {PiPokerChip} from 'react-icons/pi';
import {TbClockQuestion} from 'react-icons/tb';
import PropTypes from 'prop-types';

export const TournamentPicker = (props) => {
    // Props
    const tournamenId = props.tournamenId;
    const data = props.data;
    const Icon = data.icon;

    // Navigation
    const navigate = useNavigate();

    // Context
    const {tournamentStatus} = useDataState();
    const dataDispatch = useDataDispatch();

    // State
    const [detailsVisible, setDetailsVisible] = useState(false);
    const [playTime, setPlayTime] = useState(null);

    /**
     * Calculate tournament duration
     * @param {number} players amount of players in the tournament
     * @param {object} data tournament data object
     * @returns {number} calculated tournament duration in minutes
     */
    const playTimeHandler = (players, data) => {
        const blindLevel = getLevelBlinds(data.blinds, 5, data.startingStack * players);
        return blindLevel[3] * data.levelUpTime;
    };

    // Calculate playTime
    useEffect(() => {
        if (tournamentStatus?.tournamentPlayers?.[tournamenId]) {
            setPlayTime(playTimeHandler(tournamentStatus.tournamentPlayers[tournamenId], data));
        }
    }, [tournamentStatus.tournamentPlayers]);

    // Set player count on start
    useEffect(() => {
        dataDispatch({
            type: 'setTournamentPlayers',
            payload: {id: tournamenId, val: data.maxPlayers},
        });
    }, []);

    // Variables
    const playerOptions = (minPlayers, maxPlayers) => {
        const options = [];
        for (let i = minPlayers; i <= maxPlayers; i += 1) {
            options.push(
                <option key={`p_${tournamenId}_${i}`} value={i}>
                    {i}
                </option>,
            );
        }
        return options;
    };

    return (
        <div className="flex flex-col justify-center w-full mb-5">
            <button
                onClick={() => {
                    setDetailsVisible(!detailsVisible);
                }}
                className={`flex flex-row items-center button__default ${detailsVisible && 'active'}`}
            >
                <Icon size={48} className="mr-1 sm:mr-4 p-1" /> <span className="font-bold px-2">{data.name}</span>
                <span className="ml-auto whitespace-nowrap flex">
                    <span className="mr-2 items-center text-xs sm:text-base flex">
                        <TbClockQuestion className="mr-2 hidden sm:block" /> ~{toHoursAndMinutes(playTime)}
                    </span>
                    <span>
                        {detailsVisible ? <LuChevronUp className="ml-auto" /> : <LuChevronDown className="ml-auto" />}
                    </span>
                </span>
            </button>
            <div className={`bg-cover flex flex-col lg:flex-row p-5 ${!detailsVisible && 'hidden'}`}>
                <ul className="list-none m-0 p-0 mb-5 text-xl w-full lg:w-1/2 lg:pr-5">
                    <li className="divider">
                        <IoPersonCircleOutline className="mr-1" /> Players:
                        <select
                            className="mx-2 bg-secondary text-white rounded-md border-0 py-2 px-3 text-center"
                            defaultValue={
                                tournamentStatus?.tournamentPlayers?.[tournamenId] !== undefined
                                    ? tournamentStatus.tournamentPlayers[tournamenId]
                                    : data.maxPlayers
                            }
                            onChange={(e) => {
                                dataDispatch({
                                    type: 'setTournamentPlayers',
                                    payload: {id: tournamenId, val: e.target.value},
                                });
                            }}
                        >
                            {playerOptions(data.minPlayers, data.maxPlayers)}
                        </select>
                    </li>
                    <li className="divider">
                        <AiOutlineFieldTime className="mr-1" />
                        Playtime: ~{toHoursAndMinutes(playTime)}
                    </li>
                    <li className="divider">
                        <BiTimer className="mr-1" /> Level up time: {toHoursAndMinutes(data.levelUpTime)}
                    </li>
                    <li className="flex flex-col ">
                        <span className="flex items-center pb-2">
                            <HiMiniCircleStack className="mr-1" /> Starting stack: {data.startingStack} Chips
                        </span>
                        <ul className="list-none m-0 p-0 pl-5 ">
                            {Object.keys(data.stackStructure)
                                .reverse()
                                .map((key, i) => {
                                    return (
                                        <li key={`ss_${tournamenId}_${i}`} className="flex items-center text-sm">
                                            {data.stackStructure[key]} x <PiPokerChip className="ml-1" />
                                            {key}
                                        </li>
                                    );
                                })}
                        </ul>
                    </li>
                </ul>
                <table className="w-full text-center border-collapse lg:w-1/2">
                    <thead className="bg-complementary">
                        <tr>
                            <th className="p-1">Level</th>
                            <th className="p-1">SB</th>
                            <th className="p-1">BB</th>
                            <th className="p-1">Time</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.blinds.map((blins, i) => {
                            return (
                                <tr
                                    key={`b_${tournamenId}_${i}`}
                                    className={`${i % 2 ? 'bg-complementary' : 'bg-secondary'}`}
                                >
                                    <td className="p-1">{(i += 1)}</td>
                                    <td className="p-1">{blins[0]}</td>
                                    <td className="p-1">{blins[1]}</td>
                                    <td className="p-1">{toHoursAndMinutes(i * data.levelUpTime)}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan={4} className="py-3">
                                <Button
                                    disabled={!tournamentStatus?.tournamentPlayers?.[tournamenId]}
                                    icon="start"
                                    text="Start tournament"
                                    action={() => {
                                        data.id = tournamenId;
                                        dataDispatch({type: 'setTournamentData', payload: data});
                                        navigate('/tournament');
                                    }}
                                />
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    );
};

TournamentPicker.propTypes = {
    tournamenId: PropTypes.number,
    data: PropTypes.shape({
        id: PropTypes.number,
        icon: PropTypes.func,
        name: PropTypes.string,
        maxPlayers: PropTypes.number,
        minPlayers: PropTypes.number,
        playTime: PropTypes.number,
        levelUpTime: PropTypes.number,
        startingStack: PropTypes.number,
        stackStructure: PropTypes.object,
        blinds: PropTypes.array,
    }),
};
