import React from 'react';
import {CiBeerMugFull} from 'react-icons/ci';
import {GrPaypal} from 'react-icons/gr';
import {RiVisaLine} from 'react-icons/ri';

const tw = require('rootDir/tailwind.config.js');

export const Donate = () => {
    return (
        <main className="p-5 mx-auto flex flex-col flex-grow justify-center">
            <div className="flex items-center flex-col mb-10">
                <CiBeerMugFull
                    size={128}
                    color={tw.theme.extend.colors.yellow}
                    className="animate-[bounce_1s_ease-in-out_infinite] block"
                />
                <div className="h-4 w-16 -ml-3 bg-black rounded-circle animate-[pulse_1s_ease-in-out_0.5s_infinite]"></div>
            </div>
            <div className="text-center">
                <h2>Buy me a beer</h2>
                <p className="pb-5 md:w-96">
                    Cheers to great poker games! If you&apos;ve had a blast playing, consider funding a
                    &quot;virtual&quot; beer. Join me in the spirit of camaraderie and fun - buy a beer today!
                </p>
            </div>
            <div className="flex items-center">
                <a
                    href="https://www.paypal.com/donate?hosted_button_id=ZPSPDRNU99V4Y"
                    rel="noreferrer"
                    target="_blank"
                    className="mx-auto bg-white hover:bg-gray-100 font-semibold py-2 px-4 border border-gray-400 rounded-xl shadow flex items-center text-main cursor-pointer"
                >
                    <GrPaypal size={32} />
                    <RiVisaLine size={32} />
                </a>
            </div>
        </main>
    );
};
