/** @type {import('tailwindcss').Config} */

module.exports = {
    // Our own prefix.
    //prefix: 'xyz-',

    content: ['./src/html/**/*.html', './src/modules/**/*.jsx', './src/modules/**/*.html'],

    // https://tailwindcss.com/docs/configuration/#theme
    theme: {
        extend: {
            colors: {
                main: '#021133',
                secondary: '#002E2E',
                complementary: '#0E0234',
                cover: '#021133dd',
                white: '#ffffff',
                black: '#000000',
                yellow: '#efef0f',
            },
            minHeight: {
                '100vh': '100vh',
            },
            minWidth: {
                '100vw': '100vw',
            },
            borderRadius: {
                circle: '100%',
            },
            screens: {
                xs: '500px',
            },
            fontSize: {
                '10xl': '10rem',
                '12xl': '12rem',
            },
        },
    },

    // https://tailwindcss.com/docs/configuration/#plugins
    plugins: [],
};
