import React from 'react';
const appVersion = require('rootDir/package.json')['version'];

export const Footer = () => {
    return (
        <footer className="sticky bottom-0 px-5 flex py-2 items-center bg-slate-800 w-full shadow border-t border-slate-600 border-solid border-l-0 border-b-0 border-r-0">
            <span className="text-xs">
                <strong>72 Poker</strong>&nbsp;ver. {appVersion} by&nbsp;
                <a
                    href="https://apps.r3k.pl"
                    target="_blank"
                    rel="noreferrer"
                    className="text-white underline hover:no-underline active:no-underline"
                >
                    R3K
                </a>
            </span>
        </footer>
    );
};
