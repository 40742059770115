import React from 'react';
import PropTypes from 'prop-types';
var ReactDOMServer = require('react-dom/server');

const DataStateContext = React.createContext();
const DataDispatchContext = React.createContext();
const defaultData = structuredClone(require('modules/DataState/dataStateDefaultData.json'));

export const dataStateReducer = (state, action) => {
    switch (action.type) {
        case 'setTournamentData': {
            /**
             * Store tournament data to local storage
             */

            // Store icon if present
            action?.payload?.icon
                ? (action.payload.iconSVG = ReactDOMServer.renderToString(<action.payload.icon />))
                : null;

            // Save to local storat
            localStorage.setItem('tournamentData', JSON.stringify(action.payload));

            // Return
            return {
                ...state,
                tournamentData: action.payload,
            };
        }
        case 'setIsRunning': {
            const newTournamentStatus = structuredClone(state.tournamentStatus);
            newTournamentStatus.isRunning = action.payload;
            localStorage.setItem('tournamentStatus', JSON.stringify(newTournamentStatus));
            return {
                ...state,
                tournamentStatus: newTournamentStatus,
            };
        }
        case 'setTimerStart': {
            const newTournamentStatus = structuredClone(state.tournamentStatus);
            newTournamentStatus.timerStart = action.payload;
            localStorage.setItem('tournamentStatus', JSON.stringify(newTournamentStatus));
            return {
                ...state,
                tournamentStatus: newTournamentStatus,
            };
        }
        case 'setTimerValue': {
            const newTournamentStatus = structuredClone(state.tournamentStatus);
            newTournamentStatus.timerValue = action.payload;
            localStorage.setItem('tournamentStatus', JSON.stringify(newTournamentStatus));
            return {
                ...state,
                tournamentStatus: newTournamentStatus,
            };
        }
        case 'setCurrentLevel': {
            const newTournamentStatus = structuredClone(state.tournamentStatus);
            newTournamentStatus.currentLevel = action.payload;
            localStorage.setItem('tournamentStatus', JSON.stringify(newTournamentStatus));
            return {
                ...state,
                tournamentStatus: newTournamentStatus,
            };
        }
        case 'setMaxLevel': {
            const newTournamentStatus = structuredClone(state.tournamentStatus);
            newTournamentStatus.maxLevel = action.payload;
            localStorage.setItem('tournamentStatus', JSON.stringify(newTournamentStatus));
            return {
                ...state,
                tournamentStatus: newTournamentStatus,
            };
        }
        case 'setTournamentPlayers': {
            const newTournamentStatus = structuredClone(state.tournamentStatus);
            !newTournamentStatus?.tournamentPlayers ? (newTournamentStatus.tournamentPlayers = {}) : null;
            newTournamentStatus.tournamentPlayers[action.payload.id] = parseInt(action.payload.val, 10);
            localStorage.setItem('tournamentStatus', JSON.stringify(newTournamentStatus));
            return {
                ...state,
                tournamentStatus: newTournamentStatus,
            };
        }
        case 'setTotalStack': {
            const newTournamentStatus = structuredClone(state.tournamentStatus);
            newTournamentStatus.totalStack = action.payload;
            localStorage.setItem('tournamentStatus', JSON.stringify(newTournamentStatus));
            return {
                ...state,
                tournamentStatus: newTournamentStatus,
            };
        }
        case 'setTournamentStatus': {
            localStorage.setItem('tournamentStatus', JSON.stringify(action.payload));
            return {
                ...state,
                tournamentStatus: action.payload,
            };
        }
        case 'clearTournamentStatus': {
            const newTournamentStatus = structuredClone(defaultData.tournamentStatus);
            newTournamentStatus.tournamentPlayers = state.tournamentStatus.tournamentPlayers;
            // const defVal = structuredClone(defaultData.tournamentStatus);
            // defVal.tournamentPlayers = {};
            localStorage.setItem('tournamentStatus', JSON.stringify(newTournamentStatus));
            return {
                ...state,
                tournamentStatus: newTournamentStatus,
            };
        }
        case 'clearTournamentData': {
            const defVal = structuredClone(defaultData.tournamentData);
            localStorage.setItem('tournamentData', JSON.stringify(defVal));
            return {
                ...state,
                tournamentData: defVal,
            };
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
};

const DataStateProvider = (props) => {
    let initialData = props.defaultData;

    const [state, dispatch] = React.useReducer(dataStateReducer, initialData);

    return (
        <DataStateContext.Provider value={state}>
            <DataDispatchContext.Provider value={dispatch}>{props.children}</DataDispatchContext.Provider>
        </DataStateContext.Provider>
    );
};

const useDataState = () => {
    const context = React.useContext(DataStateContext);
    if (context === undefined) {
        throw new Error('useDataState must be used within a DataStateProvider');
    }
    return context;
};

const useDataDispatch = () => {
    const context = React.useContext(DataDispatchContext);
    if (context === undefined) {
        throw new Error('useDataDispatch must be used within a DataStateProvider');
    }
    return context;
};

DataStateProvider.propTypes = {
    defaultData: PropTypes.object,
    children: PropTypes.any,
};

export {DataStateProvider, useDataState, useDataDispatch};
