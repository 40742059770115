import React from 'react';
import {useNavigate, useLocation} from 'react-router-dom';
import {TbArrowBackUp} from 'react-icons/tb';

export const Header = () => {
    // Navigation
    const location = useLocation();
    const navigate = useNavigate();

    return (
        <header className="fixed top-0 left-0">
            {location.pathname !== '/' && (
                <div className="px-5 flex py-5 items-center w-full">
                    <button
                        onClick={() => navigate(-1)}
                        className="rounded-lg w-10 h-10 flex items-center justify-center button__default p-2"
                    >
                        <TbArrowBackUp size={18} className="text-main" />
                    </button>
                </div>
            )}
        </header>
    );
};
