import React, {useState} from 'react';
import {PiHandCoins} from 'react-icons/pi';
import {FaMedal} from 'react-icons/fa';
import {GiPodiumWinner} from 'react-icons/gi';
import PropTypes from 'prop-types';

export const Payout = ({players}) => {
    const [hidden, setHidden] = useState(true);
    const [buyin, setBuyin] = useState(0);
    const [rebuys, setRebuys] = useState(0);

    return (
        <aside
            className={`flex flex-row fixed right-0 top-5 md:top-1/2 md:bottom-auto md:-translate-y-1/2 transition-all ${
                hidden ? 'translate-x-72' : ''
            }`}
        >
            <button
                className="bg-white w-12 h-12 flex items-center justify-center p-2 text-black rounded-tl-md rounded-bl-md hover:bg-slate-200"
                onClick={() => {
                    setHidden(!hidden);
                }}
            >
                <PiHandCoins className="w-full h-full" />
            </button>
            <section className="bg-white w-72 p-3 text-black rounded-bl-md">
                <h3 className="flex items-center text-lg font-bold">Payout</h3>
                <div className="flex flex-row gap-2">
                    <fieldset className="flex flex-col">
                        <label htmlFor="payin">Buy-in</label>
                        <input
                            id="payin"
                            type="text"
                            className="bg-white w-full border-2 rounded-lg p-2"
                            value={buyin}
                            onChange={(e) => {
                                if (/^(\s*|\d+)$/.test(e.target.value)) {
                                    setBuyin(e.target.value);
                                }
                            }}
                        />
                    </fieldset>
                    <fieldset className="flex flex-col ">
                        <label htmlFor="rebuys">Rebuys</label>
                        <div className="flex flex-row gap-2 items-end">
                            <button
                                className="bg-red-700 text-white rounded-md w-11 h-11"
                                onClick={() => {
                                    if (rebuys > 0) {
                                        setRebuys(rebuys - 1);
                                    }
                                }}
                            >
                                -
                            </button>

                            <input
                                id="rebuys"
                                type="text"
                                className="bg-slate-200 border-2 rounded-lg p-2 text-center w-10"
                                disabled
                                value={rebuys}
                            />

                            <button
                                className="bg-green-700 text-white rounded-md w-11 h-11"
                                onClick={() => {
                                    setRebuys(rebuys + 1);
                                }}
                            >
                                +
                            </button>
                        </div>
                    </fieldset>
                </div>
                {buyin > 0 && (
                    <ul
                        className={`list-none p-0 m-4 font-bold ${
                            hidden ? '-translate-x-28 p-3 rounded-tl-md rounded-bl-md bg-white -mt-12' : ''
                        }`}
                    >
                        {players < 11 ? (
                            <>
                                <li className={`flex flex-row items-center  pb-3 ${hidden ? 'text-sm' : 'text-2xl'}`}>
                                    <FaMedal className="mr-2 text-orange-500" />
                                    {(players * buyin + rebuys * buyin) * 0.5}
                                </li>
                                <li className={`flex flex-row items-center pb-3 ${hidden ? 'text-sm' : 'text-xl '}`}>
                                    <FaMedal className="mr-2 text-gray-500" />
                                    {(players * buyin + rebuys * buyin) * 0.3}
                                </li>
                                <li className={`flex flex-row items-center ${hidden ? 'text-sm' : 'text-lg'}`}>
                                    <FaMedal className="mr-2 text-amber-900" />
                                    {(players * buyin + rebuys * buyin) * 0.2}
                                </li>
                            </>
                        ) : (
                            <>
                                <li className={`flex flex-row items-center  pb-3 ${hidden ? 'text-sm' : 'text-2xl'}`}>
                                    <FaMedal className="mr-2 text-orange-500" />
                                    {(players * buyin + rebuys * buyin) * 0.45}
                                </li>
                                <li className={`flex flex-row items-center pb-3 ${hidden ? 'text-sm' : 'text-xl '}`}>
                                    <FaMedal className="mr-2 text-gray-500" />
                                    {(players * buyin + rebuys * buyin) * 0.25}
                                </li>
                                <li className={`flex flex-row items-center pb-3 ${hidden ? 'text-sm' : 'text-lg'}`}>
                                    <FaMedal className="mr-2 text-amber-900" />
                                    {(players * buyin + rebuys * buyin) * 0.15}
                                </li>
                                <li className={`flex flex-row items-center pb-3 ${hidden ? 'text-sm' : 'text-base'}`}>
                                    <GiPodiumWinner className="mr-2 text-main" />
                                    {(players * buyin + rebuys * buyin) * 0.1}
                                </li>
                                <li className={`flex flex-row items-center ${hidden ? 'text-sm' : 'text-sm'}`}>
                                    <GiPodiumWinner className="mr-2 text-main" />
                                    {(players * buyin + rebuys * buyin) * 0.05}
                                </li>
                            </>
                        )}
                    </ul>
                )}
            </section>
        </aside>
    );
};

Payout.propTypes = {
    players: PropTypes.number,
};
