/**
 * Get blind for a given level
 * @param {array} blinds blinds levels array
 * @param {number} currentLevel current level
 * @param {number} totalStack total tournament stack
 * @returns {array} prev, current and next blind levels, max reasonable level
 */
export const getLevelBlinds = (blinds, currentLevel, totalStack) => {
    const newBlinds = structuredClone(blinds);

    // Calculate blinds above given range if required
    if (currentLevel >= newBlinds.length - 1) {
        for (let i = newBlinds.length; i < currentLevel + 2; i += 1) {
            const multiplier = totalStack / newBlinds[i - 1][1] < 2 ? 1 : 1.2;
            const sB = Math.round((newBlinds[i - 1][0] * multiplier) / 100) * 100;
            const bB = Math.round((newBlinds[i - 1][1] * multiplier) / 100) * 100;

            newBlinds[i] = [sB, bB];
        }
    }

    // Max reasonable level is when the BB * 10 is the total stack level
    const maxLevel = newBlinds
        .map((item) => {
            if (totalStack / item[1] < 10) {
                return true;
            }
        })
        .indexOf(true);

    // Return
    return [
        currentLevel !== 0 ? newBlinds[currentLevel - 1] : null,
        newBlinds[currentLevel],
        newBlinds[currentLevel + 1],
        maxLevel,
    ];
};
