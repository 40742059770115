import React, {useState, useEffect, useRef} from 'react';
import {useDataState, useDataDispatch} from 'modules/DataState/DataState.jsx';
import {toMinutesAndSeconds} from 'helpers/timeHelpers';
import {MdReplay} from 'react-icons/md';
import {HiPlay, HiPause} from 'react-icons/hi2';
import {TbPlayerTrackPrevFilled, TbPlayerTrackNextFilled} from 'react-icons/tb';
import PropTypes from 'prop-types';

export const Timer = (props) => {
    // Props
    const timerCallback = props.timerCallback;

    // Context
    const dataDispatch = useDataDispatch();
    const {tournamentData, tournamentStatus} = useDataState();

    // State
    const [timerValue, setTimerValue] = useState(tournamentStatus?.timerValue || null);
    const [timerStart, setTimerStart] = useState(tournamentStatus?.timerStart || null);

    // Ref
    const timerInterval = useRef();

    /**
     * Set timer start timestamp if timer is running and this timestamp is not set
     * OR
     * Clear timer interval and start timestamp if interval is set and timer is not running
     */
    useEffect(() => {
        if (tournamentStatus.isRunning && !timerStart) {
            setTimerStart(Math.round(new Date().getTime() / 1000));
            dataDispatch({type: 'setTimerStart', payload: Math.round(new Date().getTime() / 1000)});
        } else if (timerInterval.current && !tournamentStatus.isRunning) {
            clearInterval(timerInterval.current);
            setTimerStart(null);
            dataDispatch({type: 'setTimerStart', payload: null});
        }
    }, [tournamentStatus.isRunning]);

    /**
     * Set timer interval when start timestamp is set
     * Interval runs each second and calculates timer value based on start timestamp
     * current timestamp and blinds level duration
     */
    useEffect(() => {
        if (timerStart) {
            timerInterval.current = setInterval(() => {
                const secPassed = Math.round(new Date().getTime() / 1000) - timerStart;
                setTimerValue(timerValue - secPassed);
                dataDispatch({type: 'setTimerValue', payload: timerValue - secPassed});
            }, 1000);
        }
    }, [timerStart]);

    /**
     * Set timer value on init when blinds level duration is read from context data
     */
    useEffect(() => {
        if (tournamentData.levelUpTime && !timerValue) {
            setTimerValue(tournamentData.levelUpTime * 60);
            dataDispatch({type: 'setTimerValue', payload: tournamentData.levelUpTime * 60});
        }
    }, [tournamentData.levelUpTime]);

    /**
     * React to timer reach 0
     */
    useEffect(() => {
        if (timerValue <= 0 && tournamentStatus.isRunning) {
            // Init callback
            timerCallback();

            // Restart timer
            setTimerStart(Math.round(new Date().getTime() / 1000));
            dataDispatch({type: 'setTimerStart', payload: Math.round(new Date().getTime() / 1000)});
            setTimerValue(tournamentData.levelUpTime * 60);
            dataDispatch({type: 'setTimerValue', payload: tournamentData.levelUpTime * 60});

            // Clear interval as it will be fired from a different useEffect
            clearInterval(timerInterval.current);
        }
    }, [timerValue, tournamentStatus.isRunning]);

    // Cleanup
    useEffect(() => {
        return () => {
            clearInterval(timerInterval.current);
        };
    }, []);

    return (
        <section>
            <div className="font-extrabold text-8xl md:text-9xl xl:text-10xl 2xl:text-12xl text-center">
                <span>{toMinutesAndSeconds(timerValue)[0]}</span>:<span>{toMinutesAndSeconds(timerValue)[1]}</span>
            </div>
            <div className="flex justify-center">
                <button
                    className="bg-transparent border-0 text-white w-16 h-16"
                    onClick={() => {
                        dataDispatch({type: 'setCurrentLevel', payload: tournamentStatus.currentLevel - 1});
                    }}
                    disabled={tournamentStatus.currentLevel === 0}
                >
                    <TbPlayerTrackPrevFilled className="w-full h-full" />
                </button>

                {tournamentStatus.isRunning ? (
                    <button
                        className="bg-transparent border-0 text-white w-16 h-16"
                        onClick={() => {
                            dataDispatch({type: 'setIsRunning', payload: false});
                        }}
                    >
                        <HiPause className="w-full h-full" />
                    </button>
                ) : (
                    <button
                        className="bg-transparent border-0 text-white w-16 h-16"
                        onClick={() => {
                            dataDispatch({type: 'setIsRunning', payload: true});
                        }}
                    >
                        <HiPlay className="w-full h-full" />
                    </button>
                )}
                <button
                    className="bg-transparent border-0 text-white w-16 h-16"
                    onClick={() => {
                        // Change flag
                        dataDispatch({type: 'setIsRunning', payload: false});

                        // Set timer start
                        setTimerStart(null);
                        dataDispatch({type: 'setTimerStart', payload: null});

                        // Set timer value
                        setTimerValue(tournamentData.levelUpTime * 60);
                        dataDispatch({type: 'setTimerValue', payload: tournamentData.levelUpTime * 60});

                        // Clear interval
                        clearInterval(timerInterval.current);
                    }}
                >
                    <MdReplay className="w-full h-full" />
                </button>
                <button
                    className="bg-transparent border-0 text-white w-16 h-16"
                    // disabled={tournamentStatus.currentLevel >= tournamentStatus.maxLevel}
                    onClick={() => {
                        dataDispatch({type: 'setCurrentLevel', payload: tournamentStatus.currentLevel + 1});
                    }}
                >
                    <TbPlayerTrackNextFilled className="w-full h-full" />
                </button>
            </div>
        </section>
    );
};

Timer.propTypes = {
    timerCallback: PropTypes.func,
};
