import React from 'react';
import logo from 'images/72_640x640.png';
import PropTypes from 'prop-types';

export const Logo = ({noJumpout, fastJumpout}) => {
    return (
        <section
            className={`static ${
                noJumpout
                    ? ''
                    : `${fastJumpout ? 'md:absolute md:top-0 md:right-0' : '2xl:absolute 2xl:top-0 2xl:right-0'}`
            }`}
        >
            <img
                src={logo}
                alt=""
                className="object-contain h-36 md:h-56 lg:h-64 mx-auto md:ml-auto md:mx-0 block mb-5 md:mt-0"
            />
        </section>
    );
};

Logo.propTypes = {
    noJumpout: PropTypes.bool,
    fastJumpout: PropTypes.bool,
};
