import React from 'react';
import {BsFacebook} from 'react-icons/bs';
import {FaSquareXTwitter} from 'react-icons/fa6';
import {LiaTelegramPlane} from 'react-icons/lia';
import {PiLinkedinLogoFill} from 'react-icons/pi';
import {BiLogoPinterest} from 'react-icons/bi';
import PropTypes from 'prop-types';

export const Share = ({
    facebook,
    x,
    xText,
    telegram,
    telegramText,
    linkedin,
    pinterest,
    pinterestText,
    pinterestMedia,
    pageUrl,
}) => {
    return (
        <>
            {(facebook || x || telegram || linkedin || pinterest) && pageUrl ? (
                <aside className="share">
                    <h3 className="sr-only">Share</h3>
                    <ul className="share__list">
                        {facebook && (
                            <li className="">
                                <a
                                    href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(pageUrl)}`}
                                    className="share__button share__button--facebook"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <BsFacebook /> <span className="sr-only">Share on Facebook</span>
                                </a>
                            </li>
                        )}
                        {x && (
                            <li>
                                <a
                                    href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(pageUrl)}${
                                        xText ? `&text=${encodeURIComponent(xText)}` : ''
                                    }`}
                                    className="share__button share__button--x"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <FaSquareXTwitter /> <span className="sr-only">Share on X/Twitter</span>
                                </a>
                            </li>
                        )}
                        {telegram && (
                            <li>
                                <a
                                    href={`https://t.me/share/url?url=${encodeURIComponent(pageUrl)}${
                                        telegramText ? `&text=${encodeURIComponent(telegramText)}` : ''
                                    }`}
                                    className="share__button share__button--telegram"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <LiaTelegramPlane /> <span className="sr-only">Share on Telegram</span>
                                </a>
                            </li>
                        )}
                        {linkedin && (
                            <li>
                                <a
                                    href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
                                        pageUrl,
                                    )}`}
                                    className="share__button share__button--linkedin"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <PiLinkedinLogoFill /> <span className="sr-only">Share on Linkedin</span>
                                </a>
                            </li>
                        )}
                        {pinterest && (
                            <li>
                                <a
                                    href={`http://pinterest.com/pin/create/link/?url=${encodeURIComponent(pageUrl)}${
                                        pinterestMedia ? `&media=${encodeURIComponent(pinterestMedia)}` : ''
                                    }${pinterestText ? `&description=${encodeURIComponent(pinterestText)}` : ''}`}
                                    className="share__button share__button--pinterest"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <BiLogoPinterest /> <span className="sr-only">Share on Pinterest</span>
                                </a>
                            </li>
                        )}
                    </ul>
                </aside>
            ) : (
                <></>
            )}
        </>
    );
};

Share.propTypes = {
    facebook: PropTypes.bool,
    x: PropTypes.bool,
    xText: PropTypes.string,
    telegram: PropTypes.bool,
    telegramText: PropTypes.string,
    linkedin: PropTypes.bool,
    pinterest: PropTypes.bool,
    pinterestText: PropTypes.string,
    pinterestMedia: PropTypes.string,
    pageUrl: PropTypes.string,
};
